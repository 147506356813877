import React, { useState, useCallback, useEffect } from 'react';
import { Sparkles, ArrowLeft } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslatedAnalysis } from './hooks/useTranslatedAnalysis';
import { LanguageSelector } from './components/LanguageSelector';
import { TemperatureGauge } from './components/TemperatureGauge';
import { AnalysisSummary } from './components/AnalysisSummary';
import { SearchInput } from './components/SearchInput';
import { SearchHistory } from './components/SearchHistory';
import { SearchSuggestions } from './components/SearchSuggestions';
import { CookieConsent } from './components/CookieConsent';
import { SEO } from './components/SEO';
import { clsx } from 'clsx';

function App() {
  const { searchQuery } = useParams();
  const navigate = useNavigate();
  const [query, setQuery] = useState(searchQuery || '');
  const [isScrolled, setIsScrolled] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const { loading, error, results, analyze, clearResults } = useTranslatedAnalysis();
  const { t, i18n } = useTranslation();

  // Ensure language is set from localStorage on mount
  useEffect(() => {
    const savedLang = localStorage.getItem('i18nextLng');
    if (savedLang && i18n.language !== savedLang) {
      i18n.changeLanguage(savedLang);
    }
  }, [i18n]);

  // Handle URL-based search
  useEffect(() => {
    if (searchQuery && !hasSearched) {
      setQuery(searchQuery);
      analyze(searchQuery);
      setHasSearched(true);
    }
  }, [searchQuery, analyze, hasSearched]);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleSearch = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    if (!query.trim()) return;
    setHasSearched(true);
    navigate(`/${encodeURIComponent(query)}`);
    await analyze(query);
  }, [query, analyze, navigate]);

  const handleSearchClick = useCallback(async (searchQuery: string) => {
    if (!searchQuery.trim()) return;
    setQuery(searchQuery);
    setHasSearched(true);
    navigate(`/${encodeURIComponent(searchQuery)}`);
    await analyze(searchQuery);
  }, [analyze, setQuery, navigate]);

  const handleBackToCategories = useCallback(() => {
    setHasSearched(false);
    setQuery('');
    clearResults();
    navigate('/');
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [clearResults, navigate]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white">
      <SEO 
        searchQuery={query}
        category={results ? undefined : undefined}
        type="website"
        modifiedTime={new Date().toISOString()}
      />
      
      <header className={clsx(
        'fixed top-0 left-0 right-0 transition-all duration-300',
        'bg-gray-900/95 backdrop-blur-md border-b border-gray-800',
        'z-[100]',
        isScrolled ? 'shadow-lg' : ''
      )}>
        <div className="container mx-auto px-3 py-2 md:px-4 md:py-3">
          {(!isScrolled && !hasSearched) ? (
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <button
                  onClick={handleBackToCategories}
                  className={clsx(
                    'flex items-center gap-2 transition-all duration-200',
                    'hover:scale-105 active:scale-95',
                    'focus:outline-none focus:ring-2 focus:ring-blue-500/50 rounded-lg px-3 py-2'
                  )}
                >
                  <Sparkles className="w-5 h-5 md:w-6 md:h-6 text-blue-400" />
                  <h1 className="text-xl md:text-2xl font-bold whitespace-nowrap">{t('app.title')}</h1>
                </button>
                <LanguageSelector />
              </div>
              <SearchInput
                value={query}
                onChange={setQuery}
                onSearch={handleSearch}
                onSearchClick={handleSearchClick}
                loading={loading}
                isCompact={false}
              />
            </div>
          ) : (
            <div className="flex items-center gap-3">
              <button
                onClick={handleBackToCategories}
                className={clsx(
                  'p-2 text-gray-400 hover:text-white',
                  'rounded-lg hover:bg-gray-700/50',
                  'transition-all duration-200',
                  'focus:outline-none focus:ring-2 focus:ring-blue-500/50'
                )}
                aria-label={t('categories.backToCategories')}
              >
                <ArrowLeft className="w-4 h-4" />
              </button>
              <button
                onClick={handleBackToCategories}
                className={clsx(
                  'flex items-center gap-2 transition-all duration-300',
                  'scale-75 origin-left hover:scale-80 active:scale-75',
                  'focus:outline-none focus:ring-2 focus:ring-blue-500/50 rounded-lg px-2',
                  'md:flex hidden'
                )}
              >
                <Sparkles className="w-5 h-5 text-blue-400" />
                <h1 className="text-lg font-bold whitespace-nowrap">{t('app.title')}</h1>
              </button>
              <div className="flex-1">
                <SearchInput
                  value={query}
                  onChange={setQuery}
                  onSearch={handleSearch}
                  onSearchClick={handleSearchClick}
                  loading={loading}
                  isCompact={true}
                />
              </div>
              <LanguageSelector />
            </div>
          )}
        </div>
      </header>

      <main className={clsx(
        'container mx-auto px-3 md:px-4 transition-all duration-300',
        'relative z-10',
        (!isScrolled && !hasSearched) ? 'pt-32 pb-24' : 'pt-20 pb-24'
      )}>
        <div className="max-w-4xl mx-auto">
          {error && (
            <div className="bg-red-500/10 border border-red-500 rounded-lg p-3 md:p-4 mb-6">
              <p className="text-red-400 text-sm md:text-base">{error}</p>
            </div>
          )}

          {loading ? (
            <div className="text-center py-6 md:py-8">
              <div className="animate-spin rounded-full h-10 w-10 md:h-12 md:w-12 border-b-2 border-white mx-auto"></div>
              <p className="mt-4 text-gray-300 text-sm md:text-base">{t('app.search.analyzing')}</p>
            </div>
          ) : results ? (
            <div className="bg-gray-800/50 rounded-lg p-4 md:p-6 shadow-xl backdrop-blur-sm">
              <TemperatureGauge 
                sentiment={results.overallSentiment} 
                positivePercentage={results.positivePercentage} 
              />
              <AnalysisSummary results={results} />
            </div>
          ) : !hasSearched ? (
            <div className="mt-4 md:mt-8">
              <SearchSuggestions 
                onSearchClick={handleSearchClick}
                onBackToCategories={handleBackToCategories}
                showBackButton={hasSearched}
              />
            </div>
          ) : null}
        </div>
      </main>

      <SearchHistory onSearchClick={handleSearchClick} />
      <CookieConsent />
    </div>
  );
}

export default App;