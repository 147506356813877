import React from 'react';
import { useTranslation } from 'react-i18next';
import { ThumbsUp, ThumbsDown, AlertCircle } from 'lucide-react';
import type { AnalysisResult } from '../types/analysis';
import { Comment } from './Comment';
import { clsx } from 'clsx';

interface AnalysisSummaryProps {
  results: AnalysisResult;
}

export function AnalysisSummary({ results }: AnalysisSummaryProps) {
  const { t } = useTranslation();
  
  const getSummaryKey = () => {
    if (results.positivePercentage >= 70) return 'very-positive';
    if (results.positivePercentage >= 50) return 'positive';
    if (results.positivePercentage >= 30) return 'neutral';
    return 'negative';
  };

  const getIcon = () => {
    switch (getSummaryKey()) {
      case 'very-positive':
      case 'positive':
        return <ThumbsUp className="w-4 h-4 md:w-5 md:h-5 text-green-400" />;
      case 'neutral':
        return <AlertCircle className="w-4 h-4 md:w-5 md:h-5 text-yellow-400" />;
      default:
        return <ThumbsDown className="w-4 h-4 md:w-5 md:h-5 text-red-400" />;
    }
  };

  return (
    <div className="space-y-4 md:space-y-6">
      <div className="bg-gray-700/50 rounded-lg p-4 md:p-6 backdrop-blur-sm">
        <div className="flex items-start gap-3 md:gap-4">
          <div className="bg-gray-800/50 p-2.5 md:p-3 rounded-full shrink-0">
            {getIcon()}
          </div>
          <div>
            <p className={clsx(
              "text-gray-300",
              "text-sm md:text-base",
              "leading-relaxed"
            )}>
              {t(`analysis.summary.${getSummaryKey()}`, {
                count: results.totalComments,
                positive: Math.round(results.positivePercentage),
                negative: Math.round(100 - results.positivePercentage)
              })}
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-2 md:gap-4">
        <div className="bg-gray-700/50 p-3 md:p-4 rounded-lg">
          <p className="text-[10px] md:text-sm text-gray-400 mb-1">{t('analysis.stats.sentiment')}</p>
          <p className="text-xs md:text-xl font-bold truncate">
            {results.overallSentiment > 0 ? t('analysis.recommendation.positive') : t('analysis.recommendation.negative')}
          </p>
        </div>
        <div className="bg-gray-700/50 p-3 md:p-4 rounded-lg">
          <p className="text-[10px] md:text-sm text-gray-400 mb-1">{t('analysis.stats.positiveReviews')}</p>
          <p className="text-xs md:text-xl font-bold">
            {results.positivePercentage.toFixed(1)}%
          </p>
        </div>
        <div className="bg-gray-700/50 p-3 md:p-4 rounded-lg">
          <p className="text-[10px] md:text-sm text-gray-400 mb-1">{t('analysis.stats.totalComments')}</p>
          <p className="text-xs md:text-xl font-bold">{results.totalComments}</p>
        </div>
      </div>

      <div className="space-y-3 md:space-y-4">
        {results.topPositiveComments.map((comment, index) => (
          <Comment key={index} comment={comment} type="positive" />
        ))}
        {results.topNegativeComments.map((comment, index) => (
          <Comment key={index} comment={comment} type="negative" />
        ))}
      </div>
    </div>
  );
}