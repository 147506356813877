import { useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalysis } from './useAnalysis';
import { useSearchHistory } from './useSearchHistory';
import { translateComments } from '../services/translation';
import type { AnalysisResult } from '../types/analysis';

export function useTranslatedAnalysis() {
  const analysis = useAnalysis();
  const { i18n } = useTranslation();
  const { addSearch, loadSearch } = useSearchHistory();
  const [translatedResults, setTranslatedResults] = useState<AnalysisResult | null>(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const lastQueryRef = useRef<string | null>(null);

  const analyzeWithTranslation = useCallback(async (searchQuery: string) => {
    // Normalize search query
    const normalizedQuery = searchQuery.trim();
    
    // Prevent empty searches
    if (!normalizedQuery) return;

    // Prevent duplicate searches while analyzing
    if (isAnalyzing && lastQueryRef.current === normalizedQuery) {
      return;
    }

    try {
      setIsAnalyzing(true);
      lastQueryRef.current = normalizedQuery;
      setTranslatedResults(null);

      // Check cache first
      const cached = await loadSearch(normalizedQuery);
      if (cached) {
        const translated = {
          ...cached,
          topPositiveComments: await translateComments(cached.topPositiveComments, i18n.language),
          topNegativeComments: await translateComments(cached.topNegativeComments, i18n.language)
        };
        setTranslatedResults(translated);
        return;
      }

      // Perform new analysis
      const results = await analysis.analyze(normalizedQuery);
      
      if (results) { // analysis.analyze'den dönen sonuçları kullan
        const translated = {
          ...results,
          topPositiveComments: await translateComments(results.topPositiveComments, i18n.language),
          topNegativeComments: await translateComments(results.topNegativeComments, i18n.language)
        };
        
        // Save original results to cache
        await addSearch(normalizedQuery, results);
        
        // Set translated results
        setTranslatedResults(translated);
      }
    } catch (error) {
      console.error('Analysis error:', error);
    } finally {
      setIsAnalyzing(false);
    }
  }, [i18n.language, analysis, addSearch, loadSearch, isAnalyzing]);

  const clearResults = useCallback(() => {
    setTranslatedResults(null);
    setIsAnalyzing(false);
    lastQueryRef.current = null;
  }, []);

  return {
    loading: analysis.loading || isAnalyzing,
    error: analysis.error,
    results: translatedResults,
    analyze: analyzeWithTranslation,
    clearResults
  };
}