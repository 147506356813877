import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Search, Sparkles, Loader2, History, X } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSearchHistory } from '../hooks/useSearchHistory';
import { clsx } from 'clsx';

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  onSearch: (e: React.FormEvent) => Promise<void>;
  onSearchClick: (query: string) => Promise<void>;
  loading: boolean;
  isCompact?: boolean;
}

export function SearchInput({ 
  value, 
  onChange, 
  onSearch, 
  onSearchClick, 
  loading,
  isCompact = false
}: SearchInputProps) {
  const { t } = useTranslation();
  const { recentSearches } = useSearchHistory();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        // Add a small delay to allow click events to fire on suggestions
        setTimeout(() => {
          setShowSuggestions(false);
          setIsFullscreen(false);
        }, 100);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    if (loading || !value.trim() || isSubmitting) return;

    try {
      setIsSubmitting(true);
      setShowSuggestions(false);
      setIsFullscreen(false);
      await onSearch(e);
    } finally {
      setIsSubmitting(false);
    }
  }, [loading, value, isSubmitting, onSearch]);

  const handleSuggestionClick = useCallback(async (suggestion: string) => {
    if (loading || isSubmitting) return;

    try {
      setIsSubmitting(true);
      onChange(suggestion);
      await onSearchClick(suggestion);
    } catch (error) {
      console.error('Error handling suggestion click:', error);
    } finally {
      setIsSubmitting(false);
      setShowSuggestions(false);
      setIsFullscreen(false);
    }
  }, [loading, isSubmitting, onChange, onSearchClick]);

  const handleClear = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onChange('');
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [onChange]);

  const handleKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!loading && !isSubmitting && value.trim()) {
        handleSubmit(e);
      }
    }
  }, [loading, isSubmitting, value, handleSubmit]);

  const isDisabled = loading || isSubmitting || !value.trim();

  return (
    <div ref={containerRef} className="relative">
      <form onSubmit={handleSubmit} className="relative">
        <div className={clsx(
          "relative flex items-center bg-gray-700 rounded-lg transition-all duration-200",
          "group hover:bg-gray-700/80",
          isCompact ? "h-9" : "h-12",
          isFocused && "ring-2 ring-blue-500"
        )}>
          <Search className={clsx(
            "absolute left-3 text-gray-400",
            isCompact ? "w-3.5 h-3.5" : "w-4 h-4"
          )} />
          <input
            ref={inputRef}
            type="text"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            onFocus={() => {
              setIsFocused(true);
              setShowSuggestions(true);
            }}
            onBlur={() => setIsFocused(false)}
            onKeyDown={handleKeyDown}
            placeholder={t('app.search.placeholder')}
            className={clsx(
              "w-full bg-transparent text-white",
              "focus:outline-none",
              "placeholder-gray-400",
              "transition-all duration-200",
              "appearance-none rounded-lg",
              isCompact ? [
                "pl-8 pr-16",
                "py-1.5 text-sm"
              ] : [
                "pl-10 pr-24",
                "py-2 text-base"
              ]
            )}
            style={{ fontSize: '16px' }}
            disabled={isSubmitting}
          />
          <div className={clsx(
            "absolute right-2 flex items-center gap-1",
            isCompact ? "space-x-1" : "space-x-2"
          )}>
            {value && (
              <button
                type="button"
                onClick={handleClear}
                className={clsx(
                  "p-1.5 rounded-full text-gray-400 hover:text-white hover:bg-gray-600/50",
                  "transition-colors"
                )}
                disabled={isDisabled}
              >
                <X className="w-3.5 h-3.5" />
              </button>
            )}
            <button
              type="submit"
              disabled={isDisabled}
              className={clsx(
                "bg-blue-600 text-white rounded-md",
                "flex items-center transition-all duration-200",
                "hover:bg-blue-700 active:bg-blue-800",
                "disabled:opacity-50 disabled:hover:bg-blue-600",
                isCompact ? [
                  "px-2 py-1",
                  "text-xs"
                ] : [
                  "px-2.5 py-1.5",
                  "text-sm"
                ]
              )}
            >
              {(loading || isSubmitting) ? (
                <Loader2 className="w-4 h-4 animate-spin" />
              ) : (
                <>
                  <Sparkles className="w-4 h-4 md:mr-1.5" />
                  <span className="hidden md:inline">{t('app.search.button')}</span>
                </>
              )}
            </button>
          </div>
        </div>
      </form>

      {showSuggestions && recentSearches.length > 0 && (
        <div className={clsx(
          'absolute z-50 top-full left-0 right-0 mt-1',
          'bg-gray-800 border border-gray-700 rounded-lg shadow-lg'
        )}>
          <div className="p-2 border-b border-gray-700 flex items-center gap-2">
            <History className="w-4 h-4 text-gray-400" />
            <span className="text-sm text-gray-400">{t('history.recentSearches')}</span>
          </div>
          <div className="max-h-60 overflow-y-auto">
            <div className="space-y-1 p-2">
              {recentSearches.map((search, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={() => handleSuggestionClick(search)}
                  className={clsx(
                    'w-full text-left px-4 py-2',
                    'text-sm text-gray-300',
                    'hover:bg-gray-700/50 active:bg-gray-700',
                    'transition-colors duration-200',
                    'flex items-center gap-2 rounded-md',
                    'focus:outline-none focus:bg-gray-700/50',
                    loading || isSubmitting ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                  )}
                  disabled={loading || isSubmitting}
                >
                  <Search className="w-3.5 h-3.5 text-gray-500 shrink-0" />
                  <span className="flex-1 truncate">{search}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}