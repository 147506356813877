import { useState, useEffect } from 'react';
import { getAllCategories, getTrendingCategories } from '../services/categories';
import type { CategoryGroup } from '../types/categories';

export function useCategories() {
  const [categories, setCategories] = useState<CategoryGroup[]>(getAllCategories());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    let mounted = true;

    async function loadCategories() {
      try {
        setLoading(true);
        const trendingCategories = await getTrendingCategories();
        
        if (mounted && trendingCategories.length > 0) {
          setCategories(prevCategories => [
            ...trendingCategories,
            ...prevCategories
          ]);
        }
      } catch (err) {
        console.error('Error loading categories:', err);
        if (mounted) {
          setError('Failed to load trending categories');
        }
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    }

    loadCategories();

    return () => {
      mounted = false;
    };
  }, []);

  return { 
    categories, 
    loading, 
    error,
    // Her zaman en az statik kategoriler mevcut olacak
    hasCategories: categories.length > 0 
  };
}