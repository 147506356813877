import { categoryGroups as staticCategories } from '../config/categories';
import type { CategoryGroup } from '../types/categories';

const CACHE_DURATION = 6 * 60 * 60 * 1000; // 6 saat

export async function getTrendingCategories(): Promise<CategoryGroup[]> {
  try {
    // Şimdilik sadece statik kategorileri döndür
    // YouTube API ve Supabase entegrasyonu hazır olduğunda
    // dinamik kategorileri ekleyeceğiz
    return [];
  } catch (error) {
    console.error('Error fetching trending categories:', error);
    return [];
  }
}

export function getAllCategories(): CategoryGroup[] {
  return staticCategories;
}