import { supabase } from '../supabase/client';

interface SitemapEntry {
  url: string;
  lastModified: Date;
  changeFreq: 'always' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly' | 'never';
  priority: number;
  language: string;
}

export async function addSitemapEntry(entry: Omit<SitemapEntry, 'lastModified'>) {
  const { error } = await supabase
    .from('sitemap_entries')
    .upsert({
      url: entry.url,
      change_freq: entry.changeFreq,
      priority: entry.priority,
      language: entry.language
    }, {
      onConflict: 'url'
    });

  if (error) {
    console.error('Error adding sitemap entry:', error);
    throw error;
  }
}

export async function getSitemapEntries(language?: string) {
  const query = supabase
    .from('sitemap_entries')
    .select('*')
    .order('priority', { ascending: false });

  if (language) {
    query.eq('language', language);
  }

  const { data, error } = await query;

  if (error) {
    console.error('Error fetching sitemap entries:', error);
    throw error;
  }

  return data.map(entry => ({
    url: entry.url,
    lastModified: new Date(entry.last_modified),
    changeFreq: entry.change_freq,
    priority: entry.priority,
    language: entry.language
  }));
}

export async function generateSitemapXML(): Promise<string> {
  const entries = await getSitemapEntries();
  
  const xml = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
        xmlns:xhtml="http://www.w3.org/1999/xhtml"
        xmlns:news="http://www.google.com/schemas/sitemap-news/0.9"
        xmlns:image="http://www.google.com/schemas/sitemap-image/1.1">
  ${entries.map(entry => `
  <url>
    <loc>${entry.url}</loc>
    <lastmod>${entry.lastModified.toISOString()}</lastmod>
    <changefreq>${entry.changeFreq}</changefreq>
    <priority>${entry.priority}</priority>
    ${entry.language === 'en' ? `
    <xhtml:link rel="alternate" hreflang="tr" href="${entry.url.replace('/en/', '/tr/')}"/>
    ` : `
    <xhtml:link rel="alternate" hreflang="en" href="${entry.url.replace('/tr/', '/en/')}"/>
    `}
  </url>`).join('')}
</urlset>`;

  return xml;
}