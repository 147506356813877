import React from 'react';
import { useTranslation } from 'react-i18next';
import { Search, Loader2, AlertCircle, Database } from 'lucide-react';
import { useSearchHistory } from '../hooks/useSearchHistory';
import { clsx } from 'clsx';

interface SearchHistoryProps {
  onSearchClick: (query: string) => Promise<void>;
}

export function SearchHistory({ onSearchClick }: SearchHistoryProps) {
  const { t } = useTranslation();
  const { recentSearches, error, isLoading } = useSearchHistory();
  const [loadingSearch, setLoadingSearch] = React.useState<string | null>(null);

  const handleSearchClick = React.useCallback(async (query: string) => {
    if (loadingSearch) return;
    
    setLoadingSearch(query);
    try {
      await onSearchClick(query);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } finally {
      setLoadingSearch(null);
    }
  }, [onSearchClick, loadingSearch]);

  if (error) {
    return (
      <div className="fixed bottom-0 left-0 right-0 bg-red-900/80 backdrop-blur-md border-t border-red-700 p-2 z-50">
        <div className="container mx-auto flex items-center gap-2 text-red-200">
          <AlertCircle className="w-4 h-4" />
          <span className="text-sm">{error}</span>
        </div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="fixed bottom-0 left-0 right-0 bg-gray-800/95 backdrop-blur-md border-t border-gray-700 p-2 z-50">
        <div className="container mx-auto flex items-center gap-2 text-gray-400">
          <Database className="w-4 h-4 animate-pulse" />
          <span className="text-sm">Connecting to database...</span>
        </div>
      </div>
    );
  }

  if (!recentSearches?.length) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-50">
      <div className="bg-gray-900/95 backdrop-blur-md border-t border-gray-800 shadow-lg">
        <div className="container mx-auto px-2 py-2 md:px-4 md:py-3">
          <div className="flex items-center gap-2 overflow-x-auto no-scrollbar pb-1">
            {recentSearches.map((search, index) => (
              <React.Fragment key={search}>
                <button
                  onClick={() => handleSearchClick(search)}
                  disabled={loadingSearch === search}
                  aria-label={t('app.search.searchFor', { query: search })}
                  className={clsx(
                    "bg-gray-800 hover:bg-gray-700 active:bg-gray-600",
                    "px-3 py-1.5 rounded-full shrink-0",
                    "text-gray-300 hover:text-white",
                    "whitespace-nowrap flex items-center gap-2",
                    "text-xs md:text-sm transition-all duration-200",
                    "focus:outline-none focus:ring-2 focus:ring-blue-500/50",
                    "disabled:opacity-50 disabled:cursor-not-allowed",
                    "disabled:hover:bg-gray-800",
                    "shadow-sm shadow-black/10"
                  )}
                >
                  {loadingSearch === search ? (
                    <Loader2 className="w-3 h-3 md:w-3.5 md:h-3.5 animate-spin" />
                  ) : (
                    <Search className="w-3 h-3 md:w-3.5 md:h-3.5" />
                  )}
                  <span>{search}</span>
                </button>
                {index < recentSearches.length - 1 && (
                  <span className="text-gray-600 flex-shrink-0" aria-hidden="true">•</span>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}