import { createClient } from '@supabase/supabase-js';
import type { Database } from './types';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseKey) {
  throw new Error('Missing Supabase environment variables. Please connect to Supabase first.');
}

export const supabase = createClient<Database>(supabaseUrl, supabaseKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true
  },
  db: {
    schema: 'public'
  },
  global: {
    headers: {
      'X-Client-Info': 'gulle-shopping-guide@1.0.0'
    }
  }
});

let isConnected = false;
let connectionCheckPromise: Promise<boolean> | null = null;
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;
const CONNECTION_TIMEOUT = 5000; // 5 seconds timeout

const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const checkConnection = async (retryCount = 0): Promise<boolean> => {
  if (isConnected) return true;
  
  if (connectionCheckPromise) {
    return connectionCheckPromise;
  }

  connectionCheckPromise = (async () => {
    try {
      const timeoutPromise = new Promise((_, reject) => {
        setTimeout(() => reject(new Error('Connection timeout')), CONNECTION_TIMEOUT);
      });

      const checkPromise = supabase
        .from('search_history')
        .select('count')
        .limit(1)
        .single();

      const result = await Promise.race([checkPromise, timeoutPromise]);
      
      if ('error' in result && result.error) {
        throw result.error;
      }

      isConnected = true;
      return true;
    } catch (err) {
      console.warn('Supabase connection check failed:', err);
      
      if (retryCount < MAX_RETRIES) {
        await wait(RETRY_DELAY * Math.pow(2, retryCount)); // Exponential backoff
        return checkConnection(retryCount + 1);
      }
      
      isConnected = false;
      return false;
    } finally {
      setTimeout(() => {
        connectionCheckPromise = null;
      }, 1000);
    }
  })();

  return connectionCheckPromise;
};

// Reset connection status on auth changes
supabase.auth.onAuthStateChange((event) => {
  if (event === 'SIGNED_OUT') {
    console.warn('Supabase session expired');
    isConnected = false;
  }
});

// Periodic connection check
setInterval(async () => {
  if (!isConnected) {
    await checkConnection();
  }
}, 30000); // Check every 30 seconds if not connected