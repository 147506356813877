import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { addSitemapEntry } from '../services/sitemap';

export function useSitemapEntry(priority: number = 0.5, changeFreq: 'always' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly' | 'never' = 'daily') {
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    const addEntry = async () => {
      try {
        const baseUrl = 'https://gulle.org';
        const fullUrl = `${baseUrl}${location.pathname}`;
        
        await addSitemapEntry({
          url: fullUrl,
          changeFreq,
          priority,
          language: i18n.language
        });
      } catch (error) {
        console.error('Error adding sitemap entry:', error);
      }
    };

    addEntry();
  }, [location.pathname, i18n.language, priority, changeFreq]);
}