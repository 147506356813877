import { 
  Smartphone, Laptop, Headphones, Car, 
  Building2, MapPin, Palmtree, Hotel, Crown, Star, Landmark,
  UtensilsCrossed, Coffee, Soup, Coins, Bitcoin, DollarSign
} from 'lucide-react';
import type { LucideIcon } from 'lucide-react';

export interface Category {
  id: string;
  icon: LucideIcon;
  examples: string[];
}

export interface CategoryGroup {
  id: string;
  label: string;
  categories: Category[];
}

export const categoryGroups: CategoryGroup[] = [
  {
    id: 'finance',
    label: 'categories.finance.title',
    categories: [
      {
        id: 'finance.crypto',
        icon: Bitcoin,
        examples: ['Bitcoin', 'Ethereum', 'Binance Coin', 'Solana']
      },
      {
        id: 'finance.stocks',
        icon: DollarSign,
        examples: ['Apple Stock', 'Tesla Stock', 'Amazon Stock']
      },
      {
        id: 'finance.trading',
        icon: Coins,
        examples: ['Binance Exchange', 'Coinbase Exchange', 'MetaTrader']
      }
    ]
  },
  {
    id: 'products',
    label: 'categories.products.title',
    categories: [
      {
        id: 'electronics',
        icon: Smartphone,
        examples: ['iPhone 15 Pro', 'Samsung S24 Ultra', 'Google Pixel 8']
      },
      {
        id: 'computers',
        icon: Laptop,
        examples: ['MacBook Air M3', 'Dell XPS 13', 'Lenovo ThinkPad']
      },
      {
        id: 'audio',
        icon: Headphones,
        examples: ['AirPods Pro', 'Sony WH-1000XM5', 'Bose QC45']
      },
      {
        id: 'automotive',
        icon: Car,
        examples: ['BMW 3 Series', 'Mercedes C Class', 'Tesla Model 3', 'Toyota Camry']
      }
    ]
  },
  {
    id: 'food',
    label: 'categories.food.title',
    categories: [
      {
        id: 'food.restaurants',
        icon: UtensilsCrossed,
        examples: ['Nusr-Et Steakhouse', 'Zuma Dubai', 'Mikla Restaurant Istanbul']
      },
      {
        id: 'food.cafes',
        icon: Coffee,
        examples: ['Blue Bottle Coffee', 'Starbucks Reserve', 'Ministry of Coffee']
      },
      {
        id: 'food.recipes',
        icon: Soup,
        examples: ['Turkish Menemen Recipe', 'Homemade Pizza Recipe', 'Easy Tiramisu Recipe']
      }
    ]
  },
  {
    id: 'hotels',
    label: 'categories.hotels.title',
    categories: [
      {
        id: 'hotels.luxury',
        icon: Crown,
        examples: ['Four Seasons Istanbul', 'Ritz-Carlton Dubai', 'Mandarin Oriental']
      },
      {
        id: 'hotels.boutique',
        icon: Star,
        examples: ['Museum Hotel Cappadocia', 'Soho House Istanbul', 'Amanruya Bodrum']
      },
      {
        id: 'hotels.resorts',
        icon: Hotel,
        examples: ['Rixos Premium Belek', 'Maxx Royal Kemer', 'Lujo Bodrum']
      }
    ]
  },
  {
    id: 'locations',
    label: 'categories.locations.title',
    categories: [
      {
        id: 'locations.cities',
        icon: Building2,
        examples: ['Istanbul Turkey', 'Dubai UAE', 'Paris France']
      },
      {
        id: 'locations.beaches',
        icon: Palmtree,
        examples: ['Oludeniz Beach', 'JBR Beach Dubai', 'Navagio Beach']
      },
      {
        id: 'locations.landmarks',
        icon: Landmark,
        examples: ['Hagia Sophia', 'Burj Khalifa', 'Eiffel Tower']
      }
    ]
  }
];