import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { handleAPIError } from '../../utils/error';
import type { VideoComment } from '../../types/youtube';

const youtube = axios.create({
  baseURL: API_CONFIG.youtube.baseURL,
  params: {
    key: API_CONFIG.youtube.key
  },
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Origin': 'https://gulle.org'
  }
});

// Spam ve gereksiz yorumları filtreleyen fonksiyon
function isQualityComment(text: string): boolean {
  // Minimum uzunluk kontrolü
  if (text.length < 50) return false;

  // Link içeren yorumları filtrele
  if (text.includes('http')) return false;

  // Spam olabilecek kalıpları kontrol et
  const spamPatterns = [
    /check out my/i,
    /subscribe to/i,
    /visit my/i,
    /follow me/i,
    /click here/i,
    /^(good|nice|awesome|great|cool)$/i, // Tek kelimelik basit yorumlar
    /^(wow|omg|lol|haha)$/i, // Anlamsız tepkiler
    /please subscribe/i,
    /sub4sub/i,
    /follow me/i
  ];

  if (spamPatterns.some(pattern => pattern.test(text))) {
    return false;
  }

  // Sadece emoji içeren yorumları filtrele
  const emojiRegex = /^[\u{1F300}-\u{1F9FF}\u{2600}-\u{26FF}\s]+$/u;
  if (emojiRegex.test(text)) {
    return false;
  }

  // Tekrarlayan karakterleri kontrol et
  if (/(.)\1{4,}/.test(text)) {
    return false;
  }

  // Ürün değerlendirmesi içerip içermediğini kontrol et
  const reviewKeywords = [
    'review', 'quality', 'experience', 'using', 'bought', 'purchased',
    'performance', 'recommend', 'opinion', 'think', 'problem', 'issue',
    'works', 'better', 'worse', 'compared', 'price', 'worth',
    'battery', 'screen', 'camera', 'build', 'design', 'feature'
  ];

  const hasReviewContent = reviewKeywords.some(keyword => 
    text.toLowerCase().includes(keyword)
  );

  return hasReviewContent;
}

export async function searchVideos(query: string) {
  try {
    // Arama terimlerini çevirmeden, review odaklı arama yap
    const searchTerms = [
      query,
      'review',
      'experience',
      'problems',
      'long term review',
      'honest review'
    ].join(' ');
    
    const response = await youtube.get('/search', {
      params: {
        part: 'id,snippet',
        q: searchTerms,
        type: 'video',
        maxResults: API_CONFIG.youtube.maxResults,
        videoDefinition: 'high',
        relevanceLanguage: 'en',
        order: 'relevance',
        videoDuration: 'medium',
        videoType: 'any',
        safeSearch: 'none',
        videoEmbeddable: 'true'
      }
    });

    if (!response.data?.items?.length) {
      throw new Error('No results found for this search');
    }

    return response.data.items.map((item: any) => ({
      videoId: item.id.videoId,
      title: item.snippet.title,
      description: item.snippet.description
    }));
  } catch (error: any) {
    if (error.response?.status === 403) {
      console.error('YouTube API Error:', error.response?.data);
      throw new Error(ERROR_MESSAGES.QUOTA_EXCEEDED);
    }
    throw error;
  }
}

export async function getVideoComments(videoId: string): Promise<VideoComment[]> {
  try {
    const response = await youtube.get('/commentThreads', {
      params: {
        part: 'snippet',
        videoId,
        maxResults: 100,
        order: 'relevance',
        textFormat: 'plainText'
      },
    });

    if (!response.data?.items?.length) {
      return [];
    }

    return response.data.items
      .map((item: any) => ({
        id: item.id,
        text: item.snippet.topLevelComment.snippet.textDisplay,
        likeCount: item.snippet.topLevelComment.snippet.likeCount,
        publishedAt: item.snippet.topLevelComment.snippet.publishedAt,
      }))
      .filter(comment => isQualityComment(comment.text)) // Kaliteli yorumları filtrele
      .sort((a, b) => {
        // Beğeni sayısı ve yorum uzunluğuna göre sırala
        const aScore = a.likeCount * (Math.min(a.text.length / 200, 1));
        const bScore = b.likeCount * (Math.min(b.text.length / 200, 1));
        return bScore - aScore;
      })
      .slice(0, 50); // En iyi 50 yorumu al
  } catch (error: any) {
    if (error.response?.status === 403) {
      console.error('YouTube API Error:', error.response?.data);
      throw new Error(ERROR_MESSAGES.QUOTA_EXCEEDED);
    }
    throw error;
  }
}