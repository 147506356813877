import { useTranslation } from 'react-i18next';
import type { CommentSentiment } from '../../types/analysis';

// Genişletilmiş çeviri sözlüğü
const translations: Record<string, Record<string, string>> = {
  tr: {
    // Genel terimler
    'good': 'iyi',
    'bad': 'kötü',
    'excellent': 'mükemmel',
    'poor': 'zayıf',
    'quality': 'kalite',
    'price': 'fiyat',
    'recommend': 'tavsiye',
    'not': 'değil',
    'very': 'çok',
    'product': 'ürün',
    'service': 'hizmet',
    // Ürün özellikleri
    'battery': 'batarya',
    'screen': 'ekran',
    'camera': 'kamera',
    'performance': 'performans',
    'design': 'tasarım',
    'fast': 'hızlı',
    'slow': 'yavaş',
    'expensive': 'pahalı',
    'cheap': 'ucuz',
    // Duygusal ifadeler
    'love': 'seviyorum',
    'hate': 'nefret',
    'amazing': 'harika',
    'terrible': 'berbat',
    'great': 'harika',
    'awesome': 'muhteşem',
    'disappointed': 'hayal kırıklığı',
    'satisfied': 'memnun',
    'happy': 'mutlu',
    'unhappy': 'mutsuz'
  },
  de: {
    'good': 'gut',
    'bad': 'schlecht',
    'excellent': 'ausgezeichnet',
    'poor': 'schlecht',
    'quality': 'Qualität',
    'price': 'Preis',
    'recommend': 'empfehlen',
    'not': 'nicht',
    'very': 'sehr',
    'product': 'Produkt',
    'service': 'Service',
    'battery': 'Akku',
    'screen': 'Bildschirm',
    'camera': 'Kamera',
    'performance': 'Leistung',
    'design': 'Design',
    'fast': 'schnell',
    'slow': 'langsam',
    'expensive': 'teuer',
    'cheap': 'günstig',
    'love': 'liebe',
    'hate': 'hasse',
    'amazing': 'erstaunlich',
    'terrible': 'schrecklich',
    'great': 'großartig',
    'awesome': 'fantastisch',
    'disappointed': 'enttäuscht',
    'satisfied': 'zufrieden',
    'happy': 'glücklich',
    'unhappy': 'unglücklich'
  },
  fr: {
    'good': 'bon',
    'bad': 'mauvais',
    'excellent': 'excellent',
    'poor': 'pauvre',
    'quality': 'qualité',
    'price': 'prix',
    'recommend': 'recommander',
    'not': 'pas',
    'very': 'très',
    'product': 'produit',
    'service': 'service',
    'battery': 'batterie',
    'screen': 'écran',
    'camera': 'caméra',
    'performance': 'performance',
    'design': 'design',
    'fast': 'rapide',
    'slow': 'lent',
    'expensive': 'cher',
    'cheap': 'pas cher',
    'love': 'adore',
    'hate': 'déteste',
    'amazing': 'incroyable',
    'terrible': 'terrible',
    'great': 'génial',
    'awesome': 'impressionnant',
    'disappointed': 'déçu',
    'satisfied': 'satisfait',
    'happy': 'heureux',
    'unhappy': 'malheureux'
  },
  ru: {
    'good': 'хороший',
    'bad': 'плохой',
    'excellent': 'отличный',
    'poor': 'бедный',
    'quality': 'качество',
    'price': 'цена',
    'recommend': 'рекомендовать',
    'not': 'не',
    'very': 'очень',
    'product': 'продукт',
    'service': 'сервис',
    'battery': 'батарея',
    'screen': 'экран',
    'camera': 'камера',
    'performance': 'производительность',
    'design': 'дизайн',
    'fast': 'быстрый',
    'slow': 'медленный',
    'expensive': 'дорогой',
    'cheap': 'дешевый',
    'love': 'люблю',
    'hate': 'ненавижу',
    'amazing': 'удивительный',
    'terrible': 'ужасный',
    'great': 'отличный',
    'awesome': 'потрясающий',
    'disappointed': 'разочарован',
    'satisfied': 'доволен',
    'happy': 'счастливый',
    'unhappy': 'несчастный'
  },
  zh: {
    'good': '好',
    'bad': '坏',
    'excellent': '优秀',
    'poor': '差',
    'quality': '质量',
    'price': '价格',
    'recommend': '推荐',
    'not': '不',
    'very': '很',
    'product': '产品',
    'service': '服务',
    'battery': '电池',
    'screen': '屏幕',
    'camera': '相机',
    'performance': '性能',
    'design': '设计',
    'fast': '快',
    'slow': '慢',
    'expensive': '贵',
    'cheap': '便宜',
    'love': '喜欢',
    'hate': '讨厌',
    'amazing': '惊人',
    'terrible': '糟糕',
    'great': '很好',
    'awesome': '真棒',
    'disappointed': '失望',
    'satisfied': '满意',
    'happy': '开心',
    'unhappy': '不开心'
  }
};

function simpleTranslate(text: string, targetLanguage: string): string {
  if (targetLanguage === 'en') return text;
  
  const languageDict = translations[targetLanguage];
  if (!languageDict) return text;

  // Kelime kelime çeviri yap
  const words = text.split(/\b/);
  return words.map(word => {
    const lowerWord = word.toLowerCase().trim();
    return languageDict[lowerWord] || word;
  }).join('');
}

export async function translateComments(
  comments: CommentSentiment[], 
  targetLanguage: string
): Promise<CommentSentiment[]> {
  if (targetLanguage === 'en') return comments;

  return comments.map(comment => ({
    ...comment,
    text: simpleTranslate(comment.text, targetLanguage)
  }));
}