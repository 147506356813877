import { useState, useEffect } from 'react';
import { supabase, checkConnection } from '../services/supabase/client';
import type { AnalysisResult } from '../types/analysis';
import type { Database } from '../services/supabase/types';

type SearchHistory = Database['public']['Tables']['search_history']['Row'];

const MAX_HISTORY_ITEMS = 10;
const RETRY_DELAY = 2000;
const MAX_RETRIES = 3;

export function useSearchHistory() {
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  const [cachedResults, setCachedResults] = useState<Map<string, AnalysisResult>>(new Map());
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    let mounted = true;
    let retryTimeout: NodeJS.Timeout;

    const initializeConnection = async () => {
      try {
        setError(null);
        const isConnected = await checkConnection();
        
        if (!isConnected) {
          throw new Error('Unable to connect to database');
        }
        
        if (mounted) {
          await loadRecentSearches();
          setupRealtimeSubscription();
          setRetryCount(0); // Reset retry count on success
        }
      } catch (err) {
        if (mounted && retryCount < MAX_RETRIES) {
          console.warn(`Retrying connection (${retryCount + 1}/${MAX_RETRIES})...`);
          setRetryCount(prev => prev + 1);
          retryTimeout = setTimeout(initializeConnection, RETRY_DELAY * Math.pow(2, retryCount));
        } else if (mounted) {
          setError('Failed to connect to the database. Please try again later.');
          setIsLoading(false);
        }
      }
    };

    initializeConnection();

    return () => {
      mounted = false;
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    };
  }, [retryCount]);

  const setupRealtimeSubscription = () => {
    const channel = supabase
      .channel('search_history_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'search_history'
        },
        (payload) => {
          if (payload.eventType === 'INSERT') {
            const newSearch = payload.new as SearchHistory;
            setRecentSearches(prev => {
              const updated = [newSearch.query, ...prev].slice(0, MAX_HISTORY_ITEMS);
              return Array.from(new Set(updated));
            });
            if (newSearch.results) {
              setCachedResults(prev => new Map(prev).set(newSearch.query, newSearch.results as AnalysisResult));
            }
          }
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(channel);
    };
  };

  const loadRecentSearches = async () => {
    try {
      setError(null);
      const { data, error: queryError } = await supabase
        .from('search_history')
        .select('query, results')
        .order('timestamp', { ascending: false })
        .limit(MAX_HISTORY_ITEMS);

      if (queryError) throw queryError;

      if (data) {
        const searches = data.map(item => item.query);
        setRecentSearches(Array.from(new Set(searches)));
        
        const newCache = new Map<string, AnalysisResult>();
        data.forEach(item => {
          if (item.results) {
            newCache.set(item.query, item.results as AnalysisResult);
          }
        });
        setCachedResults(newCache);
      }
      setIsLoading(false);
    } catch (err) {
      console.error('Error loading recent searches:', err);
      setError('Failed to load recent searches');
      setIsLoading(false);
    }
  };

  const addSearch = async (query: string, results: AnalysisResult) => {
    try {
      if (!await checkConnection()) {
        throw new Error('No database connection');
      }

      setError(null);
      const { error: insertError } = await supabase
        .from('search_history')
        .insert([
          {
            query,
            results,
            timestamp: new Date().toISOString()
          }
        ]);

      if (insertError) throw insertError;

      setRecentSearches(prev => {
        const updated = [query, ...prev].slice(0, MAX_HISTORY_ITEMS);
        return Array.from(new Set(updated));
      });
      setCachedResults(prev => new Map(prev).set(query, results));
    } catch (err) {
      console.error('Error adding search:', err);
      setError('Failed to save search');
    }
  };

  const loadSearch = async (query: string): Promise<AnalysisResult | null> => {
    try {
      if (cachedResults.has(query)) {
        return cachedResults.get(query) || null;
      }

      if (!await checkConnection()) {
        throw new Error('No database connection');
      }

      setError(null);
      const { data, error: queryError } = await supabase
        .from('search_history')
        .select('results')
        .eq('query', query)
        .maybeSingle();

      if (queryError) throw queryError;

      if (!data?.results) return null;

      const results = data.results as AnalysisResult;
      setCachedResults(prev => new Map(prev).set(query, results));
      return results;
    } catch (err) {
      console.error('Error loading search:', err);
      setError('Failed to load search results');
      return null;
    }
  };

  return {
    recentSearches,
    addSearch,
    loadSearch,
    cachedResults,
    error,
    isLoading
  };
}