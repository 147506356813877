import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface SEOProps {
  title?: string;
  description?: string;
  keywords?: string;
  image?: string;
  url?: string;
  type?: 'website' | 'article';
  publishedTime?: string;
  modifiedTime?: string;
  category?: string;
  searchQuery?: string;
}

export function SEO({
  title,
  description,
  keywords,
  image = 'https://gulle.org/og-image.jpg',
  url = 'https://gulle.org',
  type = 'website',
  publishedTime,
  modifiedTime,
  category,
  searchQuery
}: SEOProps = {}) {
  const { i18n } = useTranslation();
  
  useEffect(() => {
    // Generate dynamic title based on search or category
    let pageTitle = title;
    if (searchQuery) {
      pageTitle = `${searchQuery} Reviews & Analysis | Gulle.org`;
    } else if (category) {
      pageTitle = `${category} Reviews & Analysis | Gulle.org`;
    }
    
    // Update title
    document.title = pageTitle || 'Gulle.org - AI-Powered Shopping Guide';
    
    // Generate dynamic description
    let pageDescription = description;
    if (searchQuery) {
      pageDescription = `Real user reviews and AI-powered analysis for ${searchQuery}. Make smarter shopping decisions with Gulle.org.`;
    } else if (category) {
      pageDescription = `Discover the best ${category.toLowerCase()} with AI-powered analysis of real user reviews and experiences.`;
    }
    
    // Update meta tags
    const metaTags = {
      'description': pageDescription || 'Make smarter shopping decisions with AI-powered analysis of real user reviews and experiences.',
      'keywords': keywords || 'AI shopping guide, product reviews, smart shopping, AI recommendations',
      'og:title': pageTitle || 'Gulle.org - AI-Powered Shopping Guide',
      'og:description': pageDescription || 'Make smarter shopping decisions with AI-powered analysis.',
      'og:image': image,
      'og:url': `${url}${i18n.language === 'tr' ? '/tr' : ''}${searchQuery ? `/search/${encodeURIComponent(searchQuery)}` : ''}`,
      'og:type': type,
      'twitter:card': 'summary_large_image',
      'twitter:title': pageTitle || 'Gulle.org - AI-Powered Shopping Guide',
      'twitter:description': pageDescription || 'Make smarter shopping decisions with AI-powered analysis.',
      'twitter:image': image,
      'twitter:url': `${url}${i18n.language === 'tr' ? '/tr' : ''}${searchQuery ? `/search/${encodeURIComponent(searchQuery)}` : ''}`,
      'article:published_time': publishedTime,
      'article:modified_time': modifiedTime,
      'article:section': category
    };
    
    Object.entries(metaTags).forEach(([name, content]) => {
      if (!content) return;
      
      let element = document.querySelector(`meta[name="${name}"]`) ||
                   document.querySelector(`meta[property="${name}"]`);
                   
      if (!element) {
        element = document.createElement('meta');
        element.setAttribute(name.startsWith('og:') || name.startsWith('twitter:') || name.startsWith('article:') ? 'property' : 'name', name);
        document.head.appendChild(element);
      }
      
      element.setAttribute('content', content);
    });
    
    // Update canonical and alternate language links
    const canonical = document.querySelector('link[rel="canonical"]');
    if (canonical) {
      canonical.setAttribute('href', `${url}${i18n.language === 'tr' ? '/tr' : ''}${searchQuery ? `/search/${encodeURIComponent(searchQuery)}` : ''}`);
    }
    
    // Update hreflang tags
    const alternates = {
      en: `${url}${searchQuery ? `/search/${encodeURIComponent(searchQuery)}` : ''}`,
      tr: `${url}/tr${searchQuery ? `/search/${encodeURIComponent(searchQuery)}` : ''}`
    };
    
    Object.entries(alternates).forEach(([lang, href]) => {
      let element = document.querySelector(`link[hreflang="${lang}"]`);
      if (!element) {
        element = document.createElement('link');
        element.setAttribute('rel', 'alternate');
        element.setAttribute('hreflang', lang);
        document.head.appendChild(element);
      }
      element.setAttribute('href', href);
    });

    // Add JSON-LD structured data
    const jsonLd = {
      '@context': 'https://schema.org',
      '@type': 'WebSite',
      'name': 'Gulle.org',
      'url': url,
      'potentialAction': {
        '@type': 'SearchAction',
        'target': {
          '@type': 'EntryPoint',
          'urlTemplate': `${url}/search/{search_term_string}`
        },
        'query-input': 'required name=search_term_string'
      }
    };

    let scriptElement = document.querySelector('script[type="application/ld+json"]');
    if (!scriptElement) {
      scriptElement = document.createElement('script');
      scriptElement.setAttribute('type', 'application/ld+json');
      document.head.appendChild(scriptElement);
    }
    scriptElement.textContent = JSON.stringify(jsonLd);

  }, [title, description, keywords, image, url, type, publishedTime, modifiedTime, category, searchQuery, i18n.language]);

  return null;
}