import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ThumbsUp, ThumbsDown, ChevronDown, ChevronUp } from 'lucide-react';
import { clsx } from 'clsx';
import type { CommentSentiment } from '../types/analysis';

interface CommentProps {
  comment: CommentSentiment;
  type: 'positive' | 'negative';
}

const CHAR_LIMIT = 120;

export function Comment({ comment, type }: CommentProps) {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);
  const [shouldShowToggle, setShouldShowToggle] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      setShouldShowToggle(comment.text.length > CHAR_LIMIT);
    }
  }, [comment.text]);

  const truncatedText = comment.text.length > CHAR_LIMIT && !isExpanded
    ? `${comment.text.slice(0, CHAR_LIMIT)}...`
    : comment.text;

  return (
    <div 
      className={clsx(
        'bg-gray-700/50 rounded-lg overflow-hidden transition-all duration-200',
        'hover:bg-gray-700/60'
      )}
    >
      <div className="p-3 md:p-4">
        <div className="flex items-start gap-2 md:gap-3">
          <div className={clsx(
            'p-1.5 md:p-2 rounded-full shrink-0',
            type === 'positive' ? 'bg-green-500/10' : 'bg-red-500/10'
          )}>
            {type === 'positive' ? (
              <ThumbsUp className="w-3.5 h-3.5 md:w-4 md:h-4 text-green-400" />
            ) : (
              <ThumbsDown className="w-3.5 h-3.5 md:w-4 md:h-4 text-red-400" />
            )}
          </div>
          <div className="flex-1 min-w-0">
            <div
              ref={contentRef}
              className={clsx(
                'text-gray-300 transition-all duration-200',
                'text-xs md:text-sm leading-relaxed',
                !isExpanded && 'line-clamp-3'
              )}
            >
              {truncatedText}
            </div>
            {shouldShowToggle && (
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className={clsx(
                  'mt-1.5 text-xs text-blue-400 hover:text-blue-300',
                  'flex items-center gap-1 transition-colors duration-200',
                  'focus:outline-none focus:ring-2 focus:ring-blue-500/50 rounded-md px-2 py-0.5 -ml-2'
                )}
              >
                {isExpanded ? (
                  <>
                    <ChevronUp className="w-3.5 h-3.5" />
                    {t('comments.showLess')}
                  </>
                ) : (
                  <>
                    <ChevronDown className="w-3.5 h-3.5" />
                    {t('comments.readMore')}
                  </>
                )}
              </button>
            )}
            <p className="text-xs text-gray-400 mt-1.5">
              {t('analysis.comments.likes', { count: comment.likeCount })}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}