import React from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, ChevronRight, Loader2 } from 'lucide-react';
import { useCategories } from '../hooks/useCategories';
import { useSitemapEntry } from '../hooks/useSitemapEntry';
import { clsx } from 'clsx';

interface SearchSuggestionsProps {
  onSearchClick: (query: string) => Promise<void>;
  onBackToCategories?: () => void;
  showBackButton?: boolean;
}

export function SearchSuggestions({ 
  onSearchClick, 
  onBackToCategories, 
  showBackButton 
}: SearchSuggestionsProps) {
  const { t } = useTranslation();
  const { categories, loading, error } = useCategories();
  const [activeTab, setActiveTab] = React.useState(categories[0]?.id);
  
  // Add page to sitemap with high priority as it's a main category page
  useSitemapEntry(0.8, 'daily');

  if (loading) {
    return (
      <div className="flex items-center justify-center py-12">
        <Loader2 className="w-6 h-6 animate-spin text-blue-400" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-8">
        <p className="text-red-400">{error}</p>
      </div>
    );
  }

  return (
    <div>
      {showBackButton && (
        <button
          onClick={onBackToCategories}
          className={clsx(
            'mb-4 flex items-center gap-2 text-gray-400',
            'hover:text-white transition-colors duration-200',
            'focus:outline-none focus:ring-2 focus:ring-blue-500/50 rounded-lg px-3 py-2'
          )}
        >
          <ArrowLeft className="w-4 h-4" />
          <span className="text-sm">{t('categories.backToCategories')}</span>
        </button>
      )}

      <div className="mb-4 overflow-x-auto no-scrollbar">
        <div className="flex gap-2 md:gap-3">
          {categories.map((group) => (
            <button
              key={group.id}
              onClick={() => setActiveTab(group.id)}
              className={clsx(
                'px-3 py-1.5 rounded-full text-sm whitespace-nowrap transition-all duration-200',
                'focus:outline-none focus:ring-2 focus:ring-blue-500/50',
                activeTab === group.id
                  ? 'bg-blue-600 text-white shadow-lg shadow-blue-500/20'
                  : 'text-gray-400 hover:text-white hover:bg-gray-700/50'
              )}
            >
              {t(group.label)}
            </button>
          ))}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
        {categories.find(group => group.id === activeTab)?.categories.map((category) => {
          const Icon = typeof category.icon === 'string' ? Loader2 : category.icon;
          return (
            <div
              key={category.id}
              className={clsx(
                'bg-gray-800 rounded-lg overflow-hidden',
                'border border-gray-700',
                'shadow-lg shadow-black/10',
                'backdrop-blur-sm'
              )}
            >
              <div className="flex items-center gap-2 p-3 border-b border-gray-700 bg-gray-800">
                <div className="p-1.5 bg-blue-500/10 rounded-md text-blue-400">
                  <Icon className="w-4 h-4" />
                </div>
                <h3 className="text-sm font-medium text-gray-200">
                  {t(`categories.${category.id}`)}
                </h3>
              </div>
              <div className="divide-y divide-gray-700">
                {category.examples.map((example) => (
                  <button
                    key={example}
                    onClick={() => onSearchClick(example)}
                    className={clsx(
                      'w-full flex items-center justify-between gap-3',
                      'px-3 py-2.5 text-left',
                      'text-sm text-gray-300',
                      'hover:bg-gray-700 active:bg-gray-600',
                      'transition-all duration-200',
                      'focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500/50'
                    )}
                  >
                    <span className="truncate">{example}</span>
                    <ChevronRight className="w-4 h-4 text-gray-500 shrink-0" />
                  </button>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}