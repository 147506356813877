import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Globe, ChevronDown } from 'lucide-react';
import { clsx } from 'clsx';

export function LanguageSelector() {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const languages = [
    { code: 'en', name: 'English', shortName: 'EN' },
    { code: 'tr', name: 'Türkçe', shortName: 'TR' },
    { code: 'de', name: 'Deutsch', shortName: 'DE' },
    { code: 'fr', name: 'Français', shortName: 'FR' },
    { code: 'ru', name: 'Русский', shortName: 'RU' },
    { code: 'zh', name: '中文', shortName: 'ZH' }
  ];

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLanguageChange = (langCode: string) => {
    i18n.changeLanguage(langCode);
    localStorage.setItem('i18nextLng', langCode);
    setIsOpen(false);
  };

  const currentLang = languages.find(lang => lang.code === i18n.language) || languages[0];

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={clsx(
          'flex items-center gap-1.5 px-2 py-1.5',
          'text-xs md:text-sm rounded-md',
          'hover:bg-gray-700/50 transition-colors',
          'text-gray-300 hover:text-white'
        )}
      >
        <Globe className="w-3.5 h-3.5" />
        <span className="hidden md:inline">{currentLang.name}</span>
        <span className="md:hidden">{currentLang.shortName}</span>
        <ChevronDown className="w-3.5 h-3.5" />
      </button>

      {isOpen && (
        <div className={clsx(
          'absolute right-0 mt-1 z-50',
          'bg-gray-800 border border-gray-700',
          'rounded-lg shadow-lg overflow-hidden',
          'min-w-[140px] max-h-[300px] overflow-y-auto'
        )}>
          {languages.map((lang) => (
            <button
              key={lang.code}
              onClick={() => handleLanguageChange(lang.code)}
              className={clsx(
                'w-full text-left px-3 py-2',
                'text-sm transition-colors',
                'hover:bg-gray-700/50',
                'flex items-center justify-between gap-2',
                i18n.language === lang.code
                  ? 'text-blue-400 bg-blue-500/10'
                  : 'text-gray-300'
              )}
            >
              <span>{lang.name}</span>
              <span className="text-xs text-gray-500">{lang.shortName}</span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}